module.exports = [
	{
		id: 'slp-cro-start-date',
		type: 'slp-cro-start-date',
		default: true,
		exclude: ['/', '/gnc/', '/slp/', '/gnc-b/'],
	},
	{
		id: 'gnc-cro-start-date',
		type: 'gnc-cro-start-date',
		default: true,
		exclude: ['/', '/slp/', '/gnc/', '/slp-b/'],
	},
];
